import styled, { css } from "styled-components";

interface Props {
  inContent?: boolean;
}

export const Loader: React.FC<Props> = (props) => {
  return (
    <LoaderContainer {...props}>
      <LoaderAnimation />
    </LoaderContainer>
  );
};

const LoaderContainer = styled.div<Props>`
  align-items: center;
  display: flex;
  justify-content: center;
  color: var(--loader-color);
  background-color: ${({ theme }) =>
    theme.custom?.background || "var(--background-color)"};
  ${(props) =>
    !props.inContent &&
    css`
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 9999;
    `};
`;

const LoaderAnimation = styled.div`
  &,
  &:before,
  &:after {
    background: var(--loader-color);
    animation: load1 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
  }
  & {
    color: var(--loader-color);
    text-indent: -9999em;
    margin: 88px auto;
    position: relative;
    font-size: 11px;
    transform: translateZ(0);
    animation-delay: -0.16s;
  }
  &:before,
  &:after {
    position: absolute;
    top: 0;
    content: "";
  }
  &:before {
    left: -1.5em;
    animation-delay: -0.32s;
  }
  &:after {
    left: 1.5em;
  }
  @keyframes load1 {
    0%,
    80%,
    100% {
      box-shadow: 0 0;
      height: 4em;
    }
    40% {
      box-shadow: 0 -2em;
      height: 5em;
    }
  }
`;
