import { useState, useEffect } from "react";

interface WindowSize {
  width: number;
  height: number;
}

export const useWindowSize = () => {
  const [innerSize, setInnerSize] = useState<WindowSize>({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    const _setInnerSize = () => {
      setInnerSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    _setInnerSize();
    window.addEventListener("resize", _setInnerSize);
    return () => {
      window.removeEventListener("resize", _setInnerSize);
    };
  }, []);
  return innerSize;
};
