import { FlexColumn } from "notes";
import { useState } from "react";
import styled from "styled-components";

export const IconWrapper = styled(FlexColumn)`
  background: rgba(0, 0, 0, 0.72);
  border-radius: 50%;
  height: 32px;
  width: 32px;
  margin-top: -5px;
  margin-bottom: -5px;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  user-select: none;
  svg {
    width: 22px;
    height: 22px;
    color: #ffffff;
    path {
      fill: #ffffff;
    }
  }
`;

export const Dropdown = ({ children, trigger }) => {
  const [isVisible, setIsVisible] = useState(false);
  const handleClose = () => {
    setIsVisible(false);
  };
  return (
    <>
      {trigger({ onClick: () => setIsVisible(!isVisible) })}
      <DropdownWrapper>
        <DropdownContainer isVisible={isVisible}>
          {children({ handleClose })}
        </DropdownContainer>
      </DropdownWrapper>
      {isVisible && <DropdownBackdrop onClick={() => setIsVisible(false)} />}
    </>
  );
};

const DropdownWrapper = styled(FlexColumn)`
  position: relative;
  padding: 0 24px;
  & > svg {
    path {
      fill: #ffffff;
    }
  }
`;

export const DropdownContainer = styled(FlexColumn)`
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.32);
  position: absolute;
  bottom: 6px;
  width: calc(100% - 50px);
  overflow: hidden;
  transition: all 200ms ease-out;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
  z-index: 101;
  overflow-y: scroll;
  max-height: 192px;
`;

export const DropdownBackdrop = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  margin: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InfoCard = styled(FlexColumn)`
  background: var(--component-background-color);
  border-radius: 8px;
  box-shadow: 0 1px 4px 0 var(--box-shadow-color);
`;
