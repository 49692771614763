import { createContext, useContext, FC } from "react";
import { ThemeProvider } from "styled-components";
import { mergeThemeOverrides, defaultBrandingContent } from "theme";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { firestore } from "firebase-internal";
import { doc } from "firebase/firestore";
import _ from "lodash";
import { useNextEvent } from "Hooks";
import { Helmet } from "react-helmet-async";
import { useHistory } from "react-router-dom";
import * as nextsong from "@musicaudienceexchange/nextsong-interface";
import { useDocumentModel } from "Hooks/models";

export type BrandingProps = nextsong.firestore.branding.Branding;

export const BrandingContext = createContext<BrandingProps>({});

export const BrandingProvider: FC = ({ children }) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const history = useHistory();
  const isDev = process.env.NODE_ENV === "development";
  const match = window.location.hostname.match(
    isDev
      ? /(?<slug>[^.]+)?\.localhost/
      : /((?<slug>[^.]+)\.)?((?<env>[^.]+)-)?set\.live$/
  );

  const { slug } = match?.groups || {};
  const subdomain = slug ?? params?.brand ?? params.brandId;

  const hasSubdomain =
    !!subdomain && !["stage-", "demo-", "dev-", "preprod-"].includes(subdomain);

  const [domain, brandLoading] = useDocumentDataOnce(
    doc(firestore, `setlive_subdomains/${subdomain}`)
  );
  const brandingId = domain?.brandingId || params.brandId;

  const [overrides, loading] = useDocumentModel(
    nextsong.firestore.branding.Branding,
    doc(firestore, `setlive_branding/${brandingId}`)
  );

  const groupId = domain?.groupId;

  const { nextEventId, nextEventLoading } = useNextEvent(groupId);

  if (hasSubdomain && (loading || brandLoading || nextEventLoading)) {
    return null;
  }

  if (nextEventId) {
    history.push(`/event/${nextEventId}`);
  }

  const mergedContent = _.merge({}, defaultBrandingContent, overrides);
  const updatedContent = {
    ...mergedContent,
    landing: {
      ...mergedContent.landing,
      blocks:
        overrides?.landing?.blocks ?? defaultBrandingContent?.landing?.blocks,
    },
  };

  const value: BrandingProps = {
    groupId,
    brandingId,
    ...updatedContent,
  };

  const updatedTheme = mergeThemeOverrides(overrides);

  return (
    <BrandingContext.Provider value={value}>
      <ThemeProvider theme={updatedTheme}>
        <Helmet>
          <link href={value?.googleFontUrl} rel="stylesheet" />
        </Helmet>
        {children}
      </ThemeProvider>
    </BrandingContext.Provider>
  );
};

export const useBrandingContext = () => useContext(BrandingContext);
