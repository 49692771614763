import { Spinner } from "melodies-source/Spinner";
import React from "react";
import styled, { css } from "styled-components";

const ButtonTypes = [
  "primary",
  "secondaryPrimary",
  "secondary",
  "outlined",
  "round",
  "tertiary",
  "destructive",
  "branded",
] as const;
export type ButtonType = (typeof ButtonTypes)[number];

const isDisabledCss = css`
  border: none;
  color: #999 !important;
  background: #e6e9eb !important;
  pointer-events: none;
`;

type Styles = {
  [K in ButtonType]?: any;
};
const styles: Styles = {
  primary: css`
    background: var(--main-color);
    color: var(--primary-button-text-color);

    :hover {
      background-color: var(--primary-button-hover-color);
    }

    ${(p: any) => p.disabled && isDisabledCss}
    ${(p: any) =>
      p.text &&
      css`
        background-color: transparent;
        color: var(--main-color);
        border: none;
        :hover {
          background-color: transparent;
          box-shadow: none;
          opacity: 0.7;
        }
      `}
  `,
  secondaryPrimary: css`
    background: var(--main-color);
    border: solid 1px var(--main-color);
    color: #ffffff;
    :hover {
      box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.3);
    }
    ${(p: any) => p.disabled && isDisabledCss}
  `,
  secondary: css`
    background: transparent;
    color: var(--text-color);
    border: solid 1px var(--text-color);
    :hover {
    }
    ${(p: any) => p.disabled && isDisabledCss}
  `,
  outlined: css`
    background: transparent;
    color: var(--main-color);
    border: solid 1px var(--main-color);
    ${(p: any) =>
      p.active &&
      css`
        background-color: #ffffff;
        color: #000000;
      `}
    :hover {
      background-color: var(--primary-button-hover-color);
      border-color: var(--primary-button-hover-color);
      color: var(--primary-button-text-color);
    }
    ${(p: any) =>
      p.disabled &&
      css`
        color: #666666;
        border-color: #666666;
        pointer-events: none;
      `};
  `,
  round: css`
    background: transparent;
    color: #ffffff;
    border: solid 1px #ffffff;
    border-radius: 25px;
    padding: 3px 16px;
    width: fit-content;
    height: 31px;
    font-size: 16px;
    line-height: 24px;
    :hover {
    }
    ${(p: any) => p.disabled && isDisabledCss}
    ${(p: any) =>
      p.active &&
      css`
        background-color: #ffffff;
        color: #000000;
      `}
  `,
  tertiary: css`
    background: transparent;
    color: var(--secondary-text-color);
    border: none;
    ${(p: any) =>
      p.disabled &&
      css`
        ${isDisabledCss};
        background: transparent;
        color: #666666;
      `}
    :hover {
      color: #333333;
      background: #f2f5f7;
      box-shadow: none;
    }
  `,
  destructive: css`
    background: #e71e3d;
    color: #ffffff;
    ${(p: any) => p.disabled && isDisabledCss}
  `,
  branded: css`
    background: linear-gradient(45deg, var(--main-color), #e71e3d);
    color: #ffffff;
    border-radius: 22px;
  `,
};

type MaxButtonProps = {
  variant?: ButtonType;
  disabled?: boolean;
};
export const MaxButton = styled.button<MaxButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  white-space: nowrap;
  min-width: auto;
  border-radius: 6px;
  border: solid 1px transparent;
  user-select: none;
  padding: 10px 24px;
  :hover {
    cursor: pointer;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
  }
  :focus {
    outline: none;
  }

  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  ${({ variant }) => styles[variant || "primary"]};

  /* ${({ theme }) => theme.mediaQueries.mobile} {
    height: 34px;
    padding: 7px 12px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  } */
`;

export type BtnProps = {
  onClick?: any;
  variant?: ButtonType;
  children: any;
  rightIcon?: JSX.Element;
  leftIcon?: JSX.Element;
  disabled?: boolean;
  text?: boolean;
  active?: boolean;
  type?: "button" | "submit" | "reset";
  style?: any;
  nudgeRight?: boolean;
  nudgeLeft?: boolean;
  loading?: boolean;
};

export const Button = ({
  onClick,
  disabled = false,
  rightIcon,
  leftIcon,
  variant = "primary",
  text,
  children,
  active,
  type = "button",
  nudgeRight,
  nudgeLeft,
  loading = false,
  ...props
}: BtnProps) => {
  return (
    <InnerButton
      type={type}
      tabIndex={0}
      onKeyDown={({ code }) => {
        if (code === "Space" || (code === "Enter" && onClick)) onClick();
      }}
      {...{
        variant,
        disabled: disabled || loading,
        active,
        text,
        onClick,
        nudgeLeft,
        nudgeRight,
        ...props,
      }}
    >
      {loading ? (
        <StyledSpinner />
      ) : (
        <>
          {leftIcon && <InnerLeft>{leftIcon}</InnerLeft>}
          {children}
          {rightIcon && <InnerRight>{rightIcon}</InnerRight>}
        </>
      )}
    </InnerButton>
  );
};

export const InnerLeft = styled.div`
  margin: 0 8px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 18px;
    height: 18px;
  }
`;

export const InnerRight = styled.div`
  margin: 0 0 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InnerButton = styled(MaxButton)<{
  nudgeRight?: boolean;
  nudgeLeft?: boolean;
}>`
  text-align: center;
  cursor: pointer;
  ${(p) =>
    p.nudgeRight &&
    css`
      ${InnerRight} {
        svg {
          transition: transform 150ms cubic-bezier(0, 0, 0.3, 1);
          transform: translateX(0);
        }
      }
      &:hover {
        ${InnerRight} {
          svg {
            transform: translateX(2px);
          }
        }
      }
    `};
  ${(p) =>
    p.nudgeLeft &&
    css`
      ${InnerLeft} {
        svg {
          transition: transform 150ms cubic-bezier(0, 0, 0.3, 1);
          transform: translateX(0);
        }
      }
      &:hover {
        ${InnerLeft} {
          svg {
            transform: translateX(-2px);
          }
        }
      }
    `};
`;

const StyledSpinner = styled(Spinner)`
  & > div > div {
    background: #999;
  }
`;
