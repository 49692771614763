import styled from "styled-components";
import { Button as ButtonBase, Link as LinkBase } from "notes";

export const Button = styled(ButtonBase)`
  background-color: ${(props) =>
    props.theme.branding.event?.colors?.action ??
    props.theme.branding.colors.action};
  color: ${(props) =>
    props.theme.branding.event?.colors?.actionLabel ??
    props.theme.branding.colors.actionLabel};
  &:not(:disabled):hover,
  &:not(:disabled):focus {
    background-color: ${(props) =>
      props.theme.branding.event?.colors?.action ??
      props.theme.branding.colors?.action};
    color: ${(props) =>
      props.theme.branding.event?.colors?.actionLabel ??
      props.theme.branding.colors.actionLabel};
  }
`;

export const ButtonSecondary = styled(ButtonBase).attrs({ type: "tertiary" })`
  background-color: ${(props) =>
    props.theme.branding.event?.colors?.actionSecondary ??
    props.theme.branding.colors.actionSecondary};
  color: ${(props) =>
    props.theme.branding.event?.colors?.actionSecondaryLabel ??
    props.theme.branding.colors.actionSecondaryLabel};
  border-color: ${(props) =>
    props.theme.branding.event?.colors?.actionSecondaryBorder ??
    props.theme.branding.colors.actionSecondaryBorder};
  &:not(:disabled):hover,
  &:not(:disabled):focus {
    background-color: ${(props) =>
      props.theme.branding.event?.colors?.actionSecondary ??
      props.theme.branding.colors?.actionSecondary};
    color: ${(props) =>
      props.theme.branding.event?.colors?.actionSecondaryLabel ??
      props.theme.branding.colors.actionSecondaryLabel};
  }
`;

export const ButtonLink = styled(ButtonBase).attrs({ type: "link" })`
  color: ${(props) =>
    props.theme.branding.event?.colors?.action ??
    props.theme.branding.colors?.action};
  &:not(:disabled):hover,
  &:not(:disabled):focus {
    color: ${(props) =>
      props.theme.branding.event?.colors?.action ??
      props.theme.branding.colors?.action};
  }
`;

export const Link = styled(LinkBase)`
  color: ${(props) =>
    props.theme.branding.event?.colors?.action ??
    props.theme.branding.colors?.action};
  &:not(:disabled):hover,
  &:not(:disabled):focus {
    color: ${(props) =>
      props.theme.branding.event?.colors?.action ??
      props.theme.branding.colors?.action};
  }
`;

export const ModalLink = styled(LinkBase)`
  color: ${(props) =>
    props.theme.branding.event?.colors?.modalSecondaryAction ??
    props.theme.branding.colors?.modalSecondaryAction ??
    props.theme.branding.event?.colors?.action ??
    props.theme.branding.colors?.action};
  &:not(:disabled):hover,
  &:not(:disabled):focus {
    color: ${(props) =>
      props.theme.branding.event?.colors?.modalSecondaryAction ??
      props.theme.branding.colors?.modalSecondaryAction ??
      props.theme.branding.event?.colors?.action ??
      props.theme.branding.colors?.action};
  }
`;

export const ModalButton = styled(ButtonBase)`
  background-color: ${(props) =>
    props.theme.branding.event?.colors?.modalAction ??
    props.theme.branding.colors?.modalAction ??
    props.theme.branding.event?.colors?.action ??
    props.theme.branding.colors.action};
  color: ${(props) =>
    props.theme.branding.event?.colors?.modalActionLabel ??
    props.theme.branding.colors?.modalActionLabel ??
    props.theme.branding.event?.colors?.actionLabel ??
    props.theme.branding.colors.actionLabel};
  &:not(:disabled):hover,
  &:not(:disabled):focus {
    background-color: ${(props) =>
      props.theme.branding.event?.colors?.modalAction ??
      props.theme.branding.colors?.modalAction ??
      props.theme.branding.event?.colors?.action ??
      props.theme.branding.colors.action};
    color: ${(props) =>
      props.theme.branding.event?.colors?.modalActionLabel ??
      props.theme.branding.colors?.modalActionLabel ??
      props.theme.branding.event?.colors?.actionLabel ??
      props.theme.branding.colors.actionLabel};
  }
`;
