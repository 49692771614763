import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  :root {
    --max-text: #222;
    --max-action: rgb(27, 0, 118);
    --main-color: #1b0076;
    --secondary-color: #953a93;
    --background-color: white;
    --component-background-color: white;
    --box-shadow-color: #e6e9eb;
    --sidebar-section-header-color: #4c4c4c;
    --text-color: #333333;
    --secondary-text-color: #666666;
    --header-text-color: black;
    --primary-button-text-color: white;
    --primary-button-hover-color: #493391;
    --border-color: #cccccc;
    --input-border-color: #cccccc;
    --hover-background-color: #f2f5f7;
    --box-shadow-color: rgba(0, 0, 0, 0.15);
    --selected-color: #e8e5f1;
    --content-background-color: #f7fafc;
    --disabled-color: #cccccc;
    --spinner-color: #e8e5f1;
  }
      
  body {
    margin: 0;
    padding: 0;
    font-family: Poppins;
  }
  
`;

export default GlobalStyle;
