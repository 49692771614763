import styled, { css } from "styled-components";
import { useArtistModalContext } from ".";
import {
  FlexColumn,
  Modal,
  H4,
  Button,
  ModalContainer,
  Label as LabelBase,
} from "notes";

export const ModalWinnerDetail = ({ open }) => {
  return (
    <StyledModal size="small" open={open}>
      <WinnerDetail />
    </StyledModal>
  );
};

export const WinnerDetail = () => {
  const { closeModal, modal, setModal } = useArtistModalContext();
  const details = modal?.winnerDetail;

  return (
    <Wrapper>
      <H4>Winner Details</H4>
      <Label light>Name</Label>
      <Label large>{details?.name}</Label>
      {details?.location && (
        <>
          <Label light>Location</Label>
          <Label large>{details?.location}</Label>
        </>
      )}
      {details?.zipcode && (
        <>
          <Label light>Zip Code</Label>
          <Label large>{details?.zipcode}</Label>
        </>
      )}
      <Label light>Email</Label>
      <Label large>{details?.email}</Label>
      {details?.phone && (
        <>
          <Label light>Phone</Label>
          <a href={`tel:${details?.phone}`} style={{ display: "inline" }}>
            <Label large>{details?.phone}</Label>
          </a>
        </>
      )}
      <Actions xEnd>
        <ButtonRed onClick={() => setModal({ confirm: details?.remove })}>
          Remove Winner
        </ButtonRed>
        <Button type="tertiary" onClick={closeModal}>
          Close
        </Button>
      </Actions>
    </Wrapper>
  );
};

const ButtonRed = styled(Button)`
  background: ${(props) => props.theme.palette.red.primary};
  &:not(:disabled):hover,
  &:not(:disabled):focus {
    background: ${(props) => props.theme.palette.red.primary};
  }
  margin-bottom: 8px;
`;

const Label = styled(LabelBase)`
  ${(props) =>
    props.light &&
    css`
      margin-top: 12px;
      color: ${(props) => props.theme.palette.gray.lighter};
      font-weight: 600;
    `};
  ${(props) =>
    props.large &&
    css`
      font-size: 17px;
      line-height: 24px;
    `};
`;

const StyledModal = styled(Modal)`
  ${ModalContainer} {
    border-radius: 8px;
    padding: 24px;
    width: 300px;
    min-height: 0;
    & > svg {
      display: none;
    }
  }
`;

const Actions = styled(FlexColumn)`
  padding: 24px 0 0 0;
  button {
    width: 100%;
  }
`;

const Wrapper = styled(FlexColumn)`
  ${H4} {
    color: ${(props) => props.theme.palette.black};
    font-weight: 700;
    text-align: center;
  }
`;
