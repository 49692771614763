import { Colors } from "custom/types";
import { GtbCustomApp } from "../gtb/types";
import { gtb } from "../gtb";

const colors: Colors = {
  main: "#EB00FF",
  secondary: "#6986ff",
  tertiary: "#6986ff",
  background: "#001670",
  text: "#eeeeee",
  headerText: "white",
  secondaryText: "#cdcdcd",
  tertiaryText: "#999999",
  primaryButtonText: "white",
  primaryButtonHover: "#f8a0ff",
  componentBackground: "#162b80",
  border: "#6d6d6d",
  inputBorder: "#ced4da",
  hoverBackground: "#963a9e",
  boxShadow: "rgba(0,0,0,0.35)",
  selected: "#892da5",
  contentBackground: "#5222DB",
  disabled: "#565656",
  loader: "#EB00FF",
  liveEventCardBackground: "#5b2670",
};

export const fizz: GtbCustomApp = {
  company: {
    logo: {
      url: "https://storage.googleapis.com/set-live-stage.appspot.com/Fizz_logo.svg",
      description: "fizz",
    },
    name: "Fizz",
  },
  event: {
    owner: {
      singular: "Program",
      plural: "Programs",
      singularWithArticle: "a Program",
    },
    type: {
      singular: "Event",
      plural: "Events",
      singularWithArticle: "an Event",
    },
    audience: {
      singular: "Attendee",
      plural: "Attendees",
      singularWithArticle: "an Attendee",
    },
  },
  footerLinks: [
    { text: "Fizz Privacy Policy", url: "https://www.max.live/privacy-policy" },
    { text: "MAX Privacy Policy", url: "https://www.max.live/privacy-policy" },
  ],
  fanAppUrl: `https://fizzevents.live`,
  artistAppUrl: `https://dashboard.fizzevents.live`,
  regionsCollection: "fizz_regions",
  routes: gtb.routes,
  theme: {
    colors,
    background: colors.contentBackground,
    pages: {
      event: {
        header: {
          background: colors.background,
        },
      },
      artist: {
        header: {
          background: `linear-gradient(180deg, ${colors.main} 0%, ${colors.tertiary} 100%), #20222A`,
        },
        eventCard: {
          divider: {
            color: colors.secondary,
          },
        },
      },
    },
    charts: {
      donut: {
        colors: [
          colors.main,
          colors.secondary,
          "#5222DB",
          "#760080",
          "#3f5099",
          "#311483",
          "#f366ff",
          "#a5b6ff",
          "#977ae9",
        ],
      },
    },
  },
};
