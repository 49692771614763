import { FC, createContext, useContext } from "react";
import { SearchClient } from "typesense";

interface ContextInterface {
  getSearchClient: (action: string, apiKey: string) => Promise<SearchClient>;
}

type ArrElement<ArrType> = ArrType extends readonly (infer ElementType)[]
  ? ElementType
  : never;

type NodeConfiguration = ArrElement<
  ConstructorParameters<typeof SearchClient>[0]["nodes"]
>;

export const SearchContext = createContext<ContextInterface>(null);

export const parseUri = (uri: string) => {
  try {
    return new URL(uri);
  } catch (error) {
    return new URL(`https://${uri}`);
  }
};

export const getNode = (uri?: string): NodeConfiguration | undefined => {
  if (uri) {
    const parsed = parseUri(uri);
    return {
      host: parsed.hostname,
      port: parseInt(parsed.port) || parsed.protocol === "https:" ? 443 : 80,
      protocol: parsed.protocol.replace(/:$/, ""),
    };
  }
};

export const getNodes = (config?: string): NodeConfiguration[] =>
  (config || "")
    .split(",")
    .map((uri) => uri.trim())
    .map(getNode)
    .filter<NodeConfiguration>((node): node is NodeConfiguration => !!node);

export const SearchProvider: FC = ({ children }) => {
  const getSearchClient = async (
    action: string,
    apiKey: string
  ): Promise<SearchClient> =>
    new SearchClient({
      nodes: getNodes(process.env.REACT_APP_SEARCH_SERVER),
      nearestNode: getNode(process.env.REACT_APP_SEARCH_SERVER_NEAREST),
      apiKey,
      numRetries: 3, // A total of 4 tries (1 original try + 3 retries)
      connectionTimeoutSeconds: 120, // Set a longer timeout for large imports
      logLevel: process.env.REACT_APP_ENV === "production" ? "silent" : "debug",
    });

  return (
    <SearchContext.Provider
      value={{
        getSearchClient,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const useSearchContext = () => useContext(SearchContext);
