import styled from "styled-components";
import { FlexColumn } from "notes";
import QRCode from "qrcode.react";
import { FlexRow, PageModal } from "Components";
import { IconClose, IconDownload } from "Images/Icons";
import { useArtistModalContext } from ".";
import { Caption, H4 } from "./common";
import { useWindowSize } from "Hooks";
import { Button as ButtonBase } from "melodies-source/Button";
import { saveSvgAsPng } from "save-svg-as-png";

const Header = styled(FlexRow)`
  background-color: var(--content-background-color);
  flex: 0 0 40px;
  padding: 12px 14px;
  position: sticky;
  top: 0;
  color: ${(props) => props.theme.colors.black[60]};
  svg {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    color: var(--text-color);
  }
`;

export const ModalQRView = ({ open }) => {
  const { closeModal } = useArtistModalContext();
  return (
    <PageModal
      open={open}
      header={
        <Header>
          <IconClose onClick={() => closeModal()} />
        </Header>
      }
    >
      <QRView />
    </PageModal>
  );
};

export const QRView = () => {
  const { modal, closeModal } = useArtistModalContext();
  const eventLink = modal?.qrView;
  const { width } = useWindowSize();

  const downloadPNG = () => {
    saveSvgAsPng(document.getElementById("qr-code"), "qrcode.png", {
      scale: 10,
    });
  };

  const downloadSVG = () => {
    const canvas = document.getElementById("qr-code");
    canvas.setAttribute("xmlns", "http://www.w3.org/2000/svg");
    const blob = new Blob([canvas.outerHTML.toString()]);
    const element = document.createElement("a");
    element.download = "qrcode.svg";
    element.href = window.URL.createObjectURL(blob);
    element.click();
    element.remove();
  };

  const imageWidth = width > 528 ? 400 : width - 128;

  return (
    <Wrapper xCenter yCenter>
      <QRCodeWrapper xCenter yCenter>
        <ImageBorder>
          <QRCode
            value={eventLink}
            size={imageWidth}
            renderAs="svg"
            id="qr-code"
          />
        </ImageBorder>
        <Container style={{ marginTop: 32 }}>
          <FlexRow>
            <FlexColumn flex="1 1 auto" style={{ marginRight: 24 }} xCenter>
              <Button
                variant="outlined"
                onClick={downloadPNG}
                leftIcon={<IconDownload />}
              >
                PNG
              </Button>
              <Caption>Download in PNG format.</Caption>
            </FlexColumn>
            <FlexColumn flex="1 1 auto" xCenter>
              <Button onClick={downloadSVG} leftIcon={<IconDownload />}>
                SVG
              </Button>
              <Caption>Download in SVG format.</Caption>
            </FlexColumn>
          </FlexRow>
        </Container>
      </QRCodeWrapper>

      <Container yEnd xCenter flex="1 1 auto">
        <Button variant="secondary" onClick={() => closeModal()}>
          Close
        </Button>
      </Container>
    </Wrapper>
  );
};

const Container = styled(FlexColumn)`
  width: 100%;
  max-width: 400px;

  ${Caption} {
    color: var(--secondary-text-color);
    margin-top: 4px;
  }
`;

const Wrapper = styled(FlexColumn)`
  background-color: var(--content-background-color);
  height: 100%;
  padding: 0 32px 32px;

  ${H4} {
    color: ${(props) => props.theme.colors.black[100]};
    font-weight: 600;
    margin-bottom: 12px;
    text-align: center;
  }
`;

const Button = styled(ButtonBase)`
  width: 100%;
  min-width: 104px;
`;

const QRCodeWrapper = styled(FlexColumn)`
  position: relative;
  flex: 1 1 100%;
  width: 100%;
`;

const ImageBorder = styled(FlexColumn)`
  padding: 32px;
  background-color: #ffffff;
  border: 1px solid ${(props) => props.theme.colors.black[20]};
  border-radius: 12px;
`;
