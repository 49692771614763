import styled, { css } from "styled-components";
import { Spinner, FlexColumn } from "Components";

const fontFamily = "'Poppins', sans-serif;";

export const H1 = styled("h1")`
  font-family: ${fontFamily};
  font-weight: 600;
  font-size: 38px;
  line-height: 54px;
`;

export const H2 = styled("h2")`
  font-family: ${fontFamily};
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
`;

export const H3 = styled("h3")`
  font-family: ${fontFamily};
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
`;

export const H4 = styled("h4")`
  font-family: ${fontFamily};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export const Subtitle1 = styled("h5")`
  font-family: ${fontFamily};
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;

export const InputLabel = styled("label")`
  font-family: ${fontFamily};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;

export const Subtitle2 = styled("h6")`
  font-family: ${fontFamily};
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
`;

export const Body1 = styled("p")`
  font-family: ${fontFamily};
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
`;

export const Body2 = styled("p")`
  font-family: ${fontFamily};
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
`;

export const Caption = styled("p")`
  font-family: ${fontFamily};
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
`;

export const LI = styled("li")`
  font-family: ${fontFamily};
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
`;

type TextProps = {
  disabled?: boolean;
  hasError?: boolean;
};

export const Label = styled.label<TextProps>`
  display: block;
  font-family: var(--max-font-primary);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  ${({ disabled }) =>
    disabled &&
    css`
      color: #999999;
    `}
`;

interface DividerProps {
  noMargin?: boolean;
}

export const Divider = styled.div<DividerProps>`
  box-sizing: border-box;
  background-color: var(--border-color);
  height: 1px;
  width: 100%;
  ${({ noMargin }) =>
    !noMargin &&
    css`
      margin: 12px 0;
    `}
`;

export const Card = styled.div`
  background: var(--component-background-color);
  border-radius: 20px;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.15);
`;

export const LoadingComponent = () => (
  <LoadingContainer>
    <Spinner />
  </LoadingContainer>
);

const LoadingContainer = styled(FlexColumn)`
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
