import { useIpLocation } from "Hooks";
import { CountryCode } from "libphonenumber-js";
import { createContext, FC, useContext, useEffect, useState } from "react";

interface LocationError {
  error: boolean;
  message?: string;
}

export interface Coordinates {
  latitude: number;
  longitude: number;
}

interface LocationContextProps {
  countryCode: CountryCode;
  location?: Coordinates;
  ipLocation?: Coordinates;
  setLocation: React.Dispatch<React.SetStateAction<Coordinates>>;
  locationLoading: boolean;
  allowLocationSearch: boolean;
  setAllowLocationSearch: React.Dispatch<React.SetStateAction<boolean>>;
  handleLocationRequest: () => void;
  hasLocationError: boolean;
  locationErrorMessage?: string;
}

export const LocationContext = createContext<LocationContextProps>(null);

export const LocationProvider: FC = ({ children }) => {
  const [location, setLocation] = useState<Coordinates>(null);
  const [locationLoading, setLocationLoading] = useState<boolean>(false);
  const [allowLocationSearch, setAllowLocationSearch] = useState<boolean>(true);
  const [locationError, setLocationError] = useState<LocationError>({
    error: false,
  });

  const { coordinates: ipLocation, countryCode } = useIpLocation();

  useEffect(() => {
    if (locationError.error && !location && !locationLoading) {
      setLocation(ipLocation);
    }
  }, [ipLocation]);

  const options: PositionOptions = {
    enableHighAccuracy: false,
    timeout: 10000,
    maximumAge: 10000,
  };

  const handleError = (error: GeolocationPositionError) => {
    const { code } = error;

    let message: LocationError;
    if (code === 1) {
      // permission denied
      message = {
        error: true,
        message: "Please enable location services to find nearby shows.",
      };
    } else if (code === 2) {
      // position unavailable
      message = {
        error: true,
        message: "We couldn't seem to find your location, please try again.",
      };
    } else {
      // timeout
      message = {
        error: true,
        message: "We've encountered a server issue, please try again.",
      };
    }
    setLocationError(message);
    setLocationLoading(false);
  };

  const hasLocationError = locationError.error;

  const handleSuccess = (geolocationPosition: GeolocationPosition) => {
    const {
      coords: { latitude, longitude },
    } = geolocationPosition;
    setLocation({ latitude, longitude });
    setLocationError({
      error: false,
    });
    setLocationLoading(false);
  };

  const handleLocationRequest = () => {
    if (!!location) setLocation(null);
    setLocationLoading(true);
    setLocationError({
      error: false,
    });
    navigator.geolocation.getCurrentPosition(
      handleSuccess,
      handleError,
      options
    );
  };

  useEffect(() => {
    if (!location && allowLocationSearch) {
      handleLocationRequest();
    }
  }, []);

  const value = {
    countryCode,
    location,
    ipLocation,
    handleLocationRequest,
    hasLocationError,
    locationErrorMessage: locationError?.message,
    locationLoading,
    setLocation,
    allowLocationSearch,
    setAllowLocationSearch,
  };

  return (
    <LocationContext.Provider value={value}>
      {children}
    </LocationContext.Provider>
  );
};

export const useLocationContext = () => useContext(LocationContext);
