import styled, { css } from "styled-components";
import { FlexColumn, FlexRow, H4 } from "notes";
import { useArtistModalContext } from ".";
import { useUser } from "auth";
import { varHexToRGB } from "Utils";
import { useCustomAppContext } from "Context/CustomAppContext";

export const SideDrawer = ({ isMenuOpen, setIsMenuOpen }) => {
  const { setEventView, eventView } = useArtistModalContext();
  const { clearSession } = useUser();
  const { customApp, language } = useCustomAppContext();

  const navArray = [
    {
      active: eventView === "upcoming",
      label: `Upcoming ${language.event.type.plural}`,
      isLite: true,
      onClick: () => setEventView("upcoming"),
    },
    {
      active: eventView === "past",
      label: `Past ${language.event.type.plural}`,
      isLite: true,
      onClick: () => setEventView("past"),
    },
    ...(customApp
      ? []
      : [
          {
            active: eventView === "qr",
            label: `${language.event.owner.singular} QR Codes`,
            isLite: true,
            onClick: () => setEventView("qr"),
          },
        ]),
    {
      label: "My Account",
      isLite: true,
      onClick: () =>
        window.open(
          customApp
            ? `${customApp.artistAppUrl}/account`
            : `https://${process.env.REACT_APP_FIREBASE_AUTHDOMAIN}/my-account`,
          "_self"
        ),
    },
    {
      label: "My Contacts",
      isLite: true,
      onClick: () =>
        window.open(
          customApp
            ? customApp.artistAppUrl
            : `https://artists.${process.env.REACT_APP_HOST}`,
          "_self"
        ),
    },
    { label: "Sign Out", onClick: () => clearSession() },
  ];

  return (
    <>
      <Wrapper isMenuOpen={isMenuOpen}>
        {navArray.map(({ active, label, isLite, onClick }, index) => (
          <NavItem
            active={active}
            key={index}
            isLite={isLite}
            onClick={() => {
              onClick();
              setIsMenuOpen(false);
            }}
          >
            <H4>{label}</H4>
          </NavItem>
        ))}
      </Wrapper>
      <Backdrop isMenuOpen={isMenuOpen} onClick={() => setIsMenuOpen(false)} />
    </>
  );
};

const NavItem = styled(FlexRow)`
  background-color: ${(props) =>
    props.isLite
      ? props.theme.custom?.background || "var(--background-color)"
      : "var(--content-background-color)"};
  border: none;
  border-bottom: 1px solid var(--border-color);
  user-select: none;
  width: 100%;
  padding: 17px 24px;

  ${H4} {
    color: var(--text-color);
  }

  ${(props) =>
    props.active &&
    css`
      background-color: ${varHexToRGB("--main-color", 0.1)};
      ${H4} {
        color: var(--main-color);
        font-weight: 600;
      }
    `};
`;

const Wrapper = styled(FlexColumn)`
  background: var(--content-background-color);
  position: fixed;
  top: 40px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 296px;
  height: 100%;
  z-index: 9;

  box-shadow: ${(props) =>
    props.isMenuOpen ? "-8px 0 8px 0 rgba(0,0,0,0.24)" : ""};
  transform: ${(props) => (props.isMenuOpen ? "" : "translateX(-100%)")};
  will-change: transform;
  transition: transform 0.2s cubic-bezier(0, 0, 0.3, 1);
`;

const Backdrop = styled(FlexColumn)`
  background: rgba(0, 0, 0, 0.38);
  position: fixed;
  top: 40px;
  right: 0;
  bottom: 0;
  left: 0;
  transition: transform 0.2s cubic-bezier(0, 0, 0.3, 1);
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 8;
  user-select: none;

  opacity: ${(props) => (props.isMenuOpen ? "0.7" : "0")};
  display: ${(props) => (props.isMenuOpen ? "block" : "none")};
`;
